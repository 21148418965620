import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
  Divider,
  IconButton,
  Link,
} from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { toast } from "react-toastify";
import { RiInstagramLine } from "react-icons/ri";
import Scroll from "react-scroll";
import { FaTelegram } from "react-icons/fa";

const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  baseSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    "& p": {
      color: theme.palette.text.textfooter,
      fontSize: "14px",
      // fontWeight: "400",
    },
    "& a": {
      textDecoration: "none",
      fontFamily: "'Sora', sans-serif",
      fontSize: "14px",
      color: theme.palette.text.dull,
    },
    "@media(max-width:565px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  privacy: {
    display: "flex",
    alignItems: "center",
    "@media(max-width:565px)": {
      marginTop: "1.5rem",
    },
  },
  subpart: {
    "& .footerimgBox": {
      position: "absolute",
      maxWidth: "330px",
      width: "auto",
      right: "50px",
      bottom: "10%",
      zIndex: "-1",
    },
    background: "rgba(255, 255, 255, 0.02)",
    position: "relative",
    zIndex: 1,
    padding: "60px 0px 40px",
    overflow: "hidden",
    "& h5": {
      color: "rgba(255, 255, 255, 0.6)",
      width: "100%",
      maxWidth: "252px",
      marginBottom: "12px",
      fontWeight: "300",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        width: "100%",
        maxWidth: "100%",
        marginBottom: "10px",
      },
    },
    "@media(max-width:599px)": {
      padding: "20px",
    },
    "& h2": {
      fontSize: "35px",
      fontWeight: "500",
      lineHeight: "46px",
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "26px",
        lineHeight: "31px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
        marginBottom: "0px",
      },
    },
    "& p": {
      color: "rgba(255, 255, 255, 0.6)",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
    "& h4": {
      fontSize: "20px",
      fontWeight: "400",
    },
  },

  listbox: {
    background: "none",
    border: "none",
    margin: "0px",
    "& a": {
      color: "rgba(255, 255, 255, 0.6)",
      display: "block",
      fontSize: "14px",
      fontWeight: "300",
      paddingTop: "4px",
      fontFamily: "'Sora', sans-serif",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
      "&:hover": {
        color: "none",
      },
    },
  },
  contactbox: {
    display: "flex",
    alignItems: "center",
    // marginLeft: "-10px",

    "& a": {
      color: "rgba(255, 255, 255, 0.6)",
      fontFamily: "'Sora', sans-serif",
      fontSize: "14px",
      fontWeight: "300",
      textDecoration: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    "& svg": {
      color: "#3F3E41",
      fontSize: "18px",
    },
  },

  buttonbox: {
    marginTop: "10px",
    "& button": {
      fontSize: "16px",
      fontWeight: "300",
      color: "#fff",
    },
  },
  subfooter: {
    paddingTop: "40px",
    paddingBottom: "32px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "0",
      paddingTop: "7px",
    },
  },
  leftbox: {
    width: "100%",
    display: "flex",
    marginTop: "20px",
    justifyContent: "center",
    "& .dot": {
      height: "40px",
      width: "40px",
      borderRadius: "50%",
      backgroundColor: "rgba(255, 255, 255, 0.04)",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "6px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "16px",
    },
  },
  icon: {
    margin: "0px 4px",
    color: "rgba(255, 255, 255, 0.15)",
    cursor: "pointer",
    width: "20px",
    "&:hover": {
      color: "#FF2676",
    },
  },
}));

export default function FooterNew() {
  const classes = useStyles();
  const history = useHistory();
  const tokenCheck = window.sessionStorage.getItem("token");

  return (
    <>
      <Box className={classes.subpart}>
        <div className="footerleft"></div>
        <img className="footerimgBox" src="images/footerimg.png" alt="" />
        <Box>
          <Container>
            <Box align="center">
              <Box>
                <img
                  src="/images/logo.png"
                  alt=""
                  width="100%"
                  style={{ maxWidth: "192px", margin: "0 auto" }}
                />
              </Box>{" "}
              <Box mt={1} mb={1}>
                <Typography variant="body2" style={{ maxWidth: "390px" }}>
                  Fieres is an Ethereum Virtual Machine (EVM) compatible
                  blockchain constructed using a dependable and safe
                  Proof-of-Authority (PoA) consensus algorithm.
                </Typography>{" "}
              </Box>
              <Box className={classes.leftbox}>
                {/* <Link target="_blank" href="https://twitter.com/">
                  <Box className="dot">
                    <TwitterIcon className={classes.icon} />
                  </Box>
                </Link> */}
                <Link target="_blank" href="https://t.me/+YEuUrv0Hw0s0NmE0">
                  <Box className="dot">
                    <FaTelegram className={classes.icon} />
                  </Box>
                </Link>
                <Link
                  target="_blank"
                  href="https://www.instagram.com/fieresofficial/?igshid=YmMyMTA2M2Y%3D"
                >
                  <Box className="dot">
                    <RiInstagramLine className={classes.icon} />
                  </Box>
                </Link>
                {/* <Link target="_blank" href="https://www.linkedin.com/">
                  <Box className="dot">
                    <LinkedInIcon className={classes.icon} />
                  </Box>
                </Link> */}
                {/* <Link target="_blank" href="https://www.discord.com/">
                  <Box className="dot">
                    <RiDiscordFill className={classes.icon} />
                  </Box>
                </Link> */}
              </Box>
            </Box>
            <Box></Box>
          </Container>
          <Box className={classes.subfooter}>
            <Divider style={{ backgroundColor: "rgb(255 255 255 / 10%)" }} />
          </Box>
          <Container>
            <Box className={classes.baseSection}>
              <Box className={classes.privacy}>
                {/* <RouterLink href="images/privacy-policy.pdf" target="_blank">
                  <Typography variant="body1">Privacy Policy </Typography>
                </RouterLink> */}
                <Link
                  href="images/privacy-policy.pdf"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  Privacy Policy
                </Link>
                &nbsp;
                <RouterLink
                  className="hovertext"
                  target="_blank"
                  to="images/Terms and Conditions 1.01-1224.pdf"
                  // to="/terms-service"
                  style={{
                    textDecoration: "none",
                    color: "gray",
                    borderLeft: "1px solid gray",
                    paddingLeft: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <ListItem>Terms & Conditions</ListItem>
                </RouterLink>
              </Box>

              <Box className={classes.contactbox} style={{ cursor: "pointer" }}>
                <Typography variant="body1" style={{ color: "#fff" }}>
                  Contact Us
                </Typography>
                <IconButton>
                  <AiOutlineMail />
                </IconButton>
                <Link href="mailto:icosupport@fieres.io">
                  icosupport@fieres.io
                </Link>
              </Box>
              <Typography variant="body2">
                Copyright©2024. Created with love by
                <span
                  className="textgradient"
                  style={{
                    fontSize: "14px",
                    marginLeft: "5px",
                    fontWeight: "500",
                  }}
                >
                  FIERES
                </span>
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
